<template>
  <div id="UnitsDetail" class="scxq">
    <div class="wrap">
      <div class="obv-wrap">
        <obvCompIndex
          ref="obvCompIndex"
          v-if="urn"
          @loaded="loaded"
        ></obvCompIndex>
      </div>
      <div class="p-content">
        <div class="pc-line" v-if = "Name">
          <div class="pcl-t">模型名称：</div>
          <div class="pcl-c">{{Name}}</div>
        </div>
        <div class="pc-line" v-if = "FloorName">
          <div class="pcl-t">模型列表名称：</div>
          <div class="pcl-c">{{FloorName}}</div>
        </div>
        <div class="pc-line" v-if = "sstext">
          <div class="pcl-t">生产状态：</div>
          <div class="pcl-c" :class = "`color${ScheduleState}`">{{sstext}}</div>
        </div>
        <div class="pc-line" v-if = "ScheduleDescribe">
          <div class="pcl-t">进度说明：</div>
          <div class="pcl-c">{{ScheduleDescribe}}</div>
        </div>
      </div>
    </div>
  </div>
</template> 
<script>
import { GetComponentModelList, GetDataList } from "@/js/firstApi";
import { getStorage, setStorage } from "@/js/common";
import obvCompIndex from "../obvs/obvViewIndex";
import { Picker, Toast } from "vant";
import wx from "weixin-js-sdk";
export default {
  name: "UnitsDetail",
  data() {
    return {
      urn: null, // 模型的 urn
      ScheduleDescribe:'',
      Name:'',
      FloorComponentID:'',
      ScheduleState:'',
      FloorName:'',
      sstext:'',
    };
  },
  components: {
    obvCompIndex,
    [Picker.name]: Picker,
    [Toast.name]: Toast,
  },
  computed: {},
  mounted() {
    let query = this.$route.query;
        console.log(query);
    if (query) {
      this.urn = query.urn;
      if(this.urn){
        this.$nextTick(() => {
          this.$refs.obvCompIndex.modelInit([this.urn], "");
        });
      }
      this.ScheduleDescribe = query.ScheduleDescribe || "";
      this.Name = query.Name;
      this.ScheduleState = query.ScheduleState;
      this.sstext = this.ScheduleState == 1?'进行中':this.ScheduleState == 2?'已完成':this.ScheduleState == 3?"已逾期":"--";
      this.FloorName = query.FloorName || "";
      this.FloorComponentID = query.ComponentID;
    }
  },
  methods: {
    loaded(modelId) {
      let r = 0;
      let g = 0;
      let b = 0;
      // if(this.ScheduleState == 1){
      //   r = 145;
      //   g = 133;
      //   b = 131;
      // }else if(this.ScheduleState == 2){
      //   r = 0;
      //   g = 128;
      //   b = 0;
      // }else if(this.ScheduleState == 3){
      //   r = 231;
      //   g = 17;
      //   b = 27;
      // }
       if(this.ScheduleState == 2){
        // r = 145;
        // g = 133;
        // b = 131;
          // color = "#29ce5b";
         r = 41;
        g = 206;
        b = 91;
      }else if(this.ScheduleState == 1){
        // r = 0;
        // g = 128;
        // b = 0;
        // color = "#0ab3ff";
          r = 10;
        g = 179;
        b = 255;
      }else if(this.ScheduleState == 3){
        // r = 231;
        // g = 17;
        // b = 27;
        //  color = "#ff0000";
            r = 255;
        g = 0;
        b = 0;
      }
      obvApiOne.setObjectsColor([
        { dbId: Number(this.FloorComponentID), modelId: modelId },
      ], r, g, b, 1);
    },
    getmodelData() {
      // 获取模型相关数据
      Toast.loading({
        duration: 0, // 持续展示 toast
        forbidClick: true,
        message: "模型数据获取中……",
      });
      let mdata = getStorage("mdata");
      if (mdata) {
        let data = mdata;
        let floordata = [];
        let componentModel = data.componentModel;
        if (componentModel && componentModel.length) {
          componentModel.forEach((res) => {
            res.text = res.name;
          });
        }
        floordata = componentModel.concat({ text: "显示全部" });
        console.log(floordata);
        this.columns02 = floordata;
        this.mlist = data.allComponentModel;
        if (data.urn) {
          this.urn = data.urn;
          console.log(this.urn);
          
        }
        Toast.clear();
      } else {
        GetComponentModelList({ BimId: "00000000-0000-0000-0000-000000000000" })
          .then((res) => {
            let { code, data, message } = { ...res.data };
            if (code == 1 && data) {
              setStorage("mdata", data);
              let floordata = [];
              let componentModel = data.componentModel;
              if (componentModel && componentModel.length) {
                componentModel.forEach((res) => {
                  res.text = res.name;
                });
              }
              floordata = componentModel.concat({ text: "显示全部" });
              this.columns02 = floordata;
              this.mlist = data.allComponentModel;
              if (data.urn) {
                this.urn = data.urn;
                console.log(this.urn);
                this.$nextTick(() => {
                  this.$refs.obvCompIndex.modelInit([data.urn], "");
                });
              }
            } else {
              console.log(message);
            }
            Toast.clear();
          })
          .catch((error) => {
            Toast.clear();
          });
      }
    },
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#UnitsDetail {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  bottom: 0;
  overflow-y: auto;
  box-sizing: border-box;
  background: #f7f7f7;
  .p-content {
    background: #fff;
    padding: 0.1rem;
    .pc-line {
      display: flex;
      font-size: 0.14rem;
      padding: 0.05rem 0;
      .pcl-t {
        font-weight: 400;
        color: #999999;
      }
      .pcl-c {
        font-weight: 400;
        color: #333333;
        &.color1{
          color:#918583;
        }
        &.color2{
          color:green;
        }
        &.color3{
          color:#E7111B;
        }
      }
    }
  }
  /deep/ {
    #v3d-viewer {
      display: none;
    }
  }
  .f-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-width: 1rem;
    height: 0.4rem;
    padding: 0 0.1rem;
    background: #fff;
    &.s02 {
      border-bottom: 1px solid #f7f7f7;
      .fs-text {
        color: #333333;
      }
    }
    &.s03 {
      border-bottom: 1px solid #f7f7f7;

      .fs-text {
        color: #333333;
      }
    }
    &.s04 {
      border-bottom: none;
      padding: 0;
    }
    .s-t {
      font-size: 0.14rem;
      color: #333333;
    }
    .fs-text {
      font-size: 0.14rem;
      display: flex;
      align-items: center;
      color: #0083ff;
      .status1 {
        color: #2ace5c;
      }
      .status2 {
        color: #8d928e;
      }
      .status3 {
        color: #ce432a;
      }
    }
    .icon-s {
      width: 0.2rem;
      height: 0.2rem;
      object-fit: contain;
      transform: rotate(-90deg);
    }
  }
  .wrap {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding: 0 0 0.45rem;
    overflow-y: auto;
    .des-c {
      // margin: 8px 0 0;
      padding: 0 0.1rem;
      .van-cell {
        border: 1px solid #ddd;
        border-radius: 3px;
        background: #f3f3f3;
        font-size: 0.14rem;
        color: #333333;
      }
    }
    .obv-wrap {
      width: 100%;
      height: 3rem;
      position: relative;
    }
    .component-selected {
      font-size: 0.12rem;
      line-height: 0.2rem;
      color: red;
      margin: 10px 0 0;
      padding: 0 0.26rem;
      position: relative;
      .icon-f {
        color: #fff;
        background: red;
        width: 0.13rem;
        height: 0.13rem;
        line-height: 0.13rem;
        text-align: center;
        position: absolute;
        left: 0.1rem;
        top: 50%;
        transform: translate(0, -50%);
        border-radius: 50%;
      }
      span {
        padding: 0 10px 0 0;
      }
    }
  }
  .van-picker {
    position: absolute;
    width: 100%;
    bottom: 0;
    left: 0;
  }
  .btn-s {
    height: 0.45rem;
    line-height: 0.45rem;
    color: #fff;
    font-size: 0.15rem;
    text-align: center;
    position: absolute;
    bottom: 0;
    background: #0083ff;
    left: 0;
    right: 0;
    &.disable {
      opacity: 0.3;
    }
    &.b02 {
      right: 0;
      background: green;
    }
    &.b03 {
      right: 40%;
      background: red;
    }
  }
}
</style>